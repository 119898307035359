
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.cms-icons {
  margin: 3rem 0;

  .cms-icon {
    overflow: hidden;

    .bi {
      font-size: $font-size-base * 2;
      transition: $transition-base;
    }

    &:hover {
      .bi {
        transform: scale(2);
      }
    }
  }
}
